import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver'
import ImageModule from 'docxtemplater-image-module-free'
import JSZip from 'jszip';


/**
 * 将base64格式的数据转为ArrayBuffer
 * @param {Object} dataURL base64格式的数据
 */
function base64DataURLToArrayBuffer(dataURL) {
    const base64Regex = /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
    if (!base64Regex.test(dataURL)) {
        return false;
    }
    const stringBase64 = dataURL.replace(base64Regex, "");
    let binaryString;
    if (typeof window !== "undefined") {
        binaryString = window.atob(stringBase64);
    } else {
        binaryString = new Buffer(stringBase64, "base64").toString("binary");
    }
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes.buffer;
}

export const ExportBriefDataDocx = (tempDocxPath, data, fileName, imgSize) => {
    console.log(tempDocxPath, data, fileName, 111)
    var expressions = require('angular-expressions')
    var assign = require('lodash/assign')
    expressions.filters.lower = function (input) {
        // This condition should be used to make sure that if your input is
        // undefined, your output will be undefined as well and will not
        // throw an error
        if (!input) return input
        // toLowerCase() 方法用于把字符串转换为小写。
        return input.toLowerCase()
    }
    expressions.filters.size = function (input, width, height) {
        return {
            data: input,
            size: [width, height],
        };
    };
    function angularParser(tag) {
        tag = tag
            .replace(/^\.$/, 'this')
            .replace(/(’|‘)/g, "'")
            .replace(/(“|”)/g, '"')
        const expr = expressions.compile(tag)
        return {
            get: function (scope, context) {
                let obj = {}
                const scopeList = context.scopeList
                const num = context.num
                for (let i = 0, len = num + 1; i < len; i++) {
                    obj = assign(obj, scopeList[i])
                }
                return expr(scope, obj)
            }
        }
    }


    JSZipUtils.getBinaryContent(tempDocxPath, (error, content) => {
        if (error) {
            console.log(error)
        }
        let modules = []
        if (imgSize) {
            let opts = {}
            // 图片处理
            opts = {
                //图像是否居中
                centered: imgSize.centered || false
            };

            opts.getImage = (chartId) => {
                //console.log(chartId);//base64数据
                //将base64的数据转为ArrayBuffer
                return base64DataURLToArrayBuffer(chartId);
            }

            opts.getSize = function (img, tagValue, tagName) {
                // console.log(1,img);//ArrayBuffer数据
                // console.log(2,tagValue);//base64数据
                // console.log(3,tagName);//wordData对象的图像属性名
                //自定义指定图像大小
                if (imgSize.hasOwnProperty(tagName)) {
                    return imgSize[tagName];
                } else {
                    return [600, 350];
                }
            }
            modules.push(new ImageModule(opts))
        }

        // 创建一个JSZip实例，内容为模板的内容
        // let zip = new JSZip(content); //用PizZip替代
        const zip = new PizZip(content)
        // 创建并加载 Docxtemplater 实例对象
        const doc = new Docxtemplater(zip, { parser: angularParser, modules: modules })
        // 设置模板变量的值
        doc.setData(data)
        try {
            // 呈现文档，会将内部所有变量替换成值，
            doc.render()
        } catch (error) {
            const e = {
                message: error.message,
                name: error.name,
                stack: error.stack,
                properties: error.properties

            }
            console.log({ error: e })
            // 当使用json记录时，此处抛出错误信息
            throw error
        }
        // 生成一个代表Docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
        const out = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        })
        // 将目标文件对象保存为目标类型的文件，并命名
        saveAs(out, fileName)
    })
}
export const ExportBriefDataDocx1 = (tempDocxpath, list, zipName, imgSize) => {
    const promises = [];
    const zips = new JSZip();

    var expressions = require('angular-expressions')
    var assign = require('lodash/assign')
    expressions.filters.lower = function (input) {
        // This condition should be used to make sure that if your input is
        // undefined, your output will be undefined as well and will not
        // throw an error
        if (!input) return input
        // toLowerCase() 方法用于把字符串转换为小写。
        return input.toLowerCase()
    }
    expressions.filters.size = function (input, width, height) {
        return {
            data: input,
            size: [width, height],
        };
    };
    function angularParser(tag) {
        tag = tag
            .replace(/^\.$/, 'this')
            .replace(/(’|‘)/g, "'")
            .replace(/(“|”)/g, '"')
        const expr = expressions.compile(tag)
        return {
            get: function (scope, context) {
                let obj = {}
                const scopeList = context.scopeList
                const num = context.num
                for (let i = 0, len = num + 1; i < len; i++) {
                    obj = assign(obj, scopeList[i])
                }
                return expr(scope, obj)
            }
        }
    }
    console.log(174, list)

    list.forEach((element, index) => {
        console.log(177, element)
        const fileName = `实习企业考察报告【${element.firm_name}】.docx`;
        const data = element;
        const promise = new Promise((resolver, reject) => {
            JSZipUtils.getBinaryContent(tempDocxpath, (error, content) => {
                if (error) {
                    console.log(error)
                    throw error;
                }
                let modules = []
                if (imgSize) {
                    let opts = {}
                    // 图片处理
                    opts = {
                        //图像是否居中
                        centered: imgSize.centered || false
                    };

                    opts.getImage = (chartId) => {
                        //console.log(chartId);//base64数据
                        //将base64的数据转为ArrayBuffer
                        return base64DataURLToArrayBuffer(chartId);
                    }

                    opts.getSize = function (img, tagValue, tagName) {
                        // console.log(1,img);//ArrayBuffer数据
                        // console.log(2,tagValue);//base64数据
                        // console.log(3,tagName);//wordData对象的图像属性名
                        //自定义指定图像大小
                        if (imgSize.hasOwnProperty(tagName)) {
                            return imgSize[tagName];
                        } else {
                            return [600, 350];
                        }
                    }
                    modules.push(new ImageModule(opts))
                }

                const zip = new PizZip(content);
                // let doc = new Docxtemplater(zip, { parser: angularParser, modules: modules }).loadZip(zip);
                let doc = new Docxtemplater(zip, { parser: angularParser, modules: modules })
                doc.setData(data);
                try {
                    doc.render();
                } catch (error) {
                    const e = {
                        "message": error.message,
                        "name": error.name,
                        "stack": error.stack,
                        "properties": error.properties
                    };
                    console.log({ error: e });
                    throw error;
                }
                const out = doc.getZip().generate({
                    type: "blob",
                    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                zips.file(fileName, out, {
                    base64: true,
                    compression: 'DEFLATE', // STORE：默认不压缩 DEFLATE：需要压缩
                    compressionOptions: {
                        level: 9, // 压缩等级1~9    1压缩速度最快，9最优压缩方式
                    }
                })
                resolver();
            });
        })
        promises.push(promise);
    });
    // console.log(351, promises)
    Promise.all(promises).then(() => {
    //     console.log('zips', zips, promises)
        zips.generateAsync({ type: "blob" }).then(content => {
            //生成二进制流
            saveAs(content, zipName);
            // const fs = require('fs');
            // fs.writeFileSync('output.zip', content, 'binary');
        }).finally(() => {
            console.log(253)

         })
    })
}
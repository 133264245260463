<template>
    <div class="pageContainer targetPage flexCloumn">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">

                <el-date-picker size="small" v-model="searchForm.month" value-format="yyyy-MM" type="month"
                    placeholder="选择月" style="width:150px;margin: 0 10px 10px 0;">
                </el-date-picker>
                <!-- <el-input placeholder="请输入关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
                    style="width:300px;margin: 0 10px 10px 0;" clearable>
                    <el-select v-model="searchForm.ktype" slot="prepend" placeholder="请选择" style="width:120px">
                        <el-option label="企业名称" value="username"></el-option>

                    </el-select>
                </el-input> -->










                <el-button icon="el-icon-search" style="margin: 0 10px 10px 0;" size="small" type="primary"
                    @click="onSearch">
                    搜索
                </el-button>
                <el-button icon="el-icon-plus" size="small" type="primary" plain style="margin: 0 10px 10px 0;"
                    @click="addNew">新增考察表</el-button>
                <el-button icon="el-icon-download" size="small" type="success" plain style="margin: 0 10px 10px 0;"
                    @click="exportReportAll">导出全部企业考察表</el-button>
            </div>

            <!-- <div class="flexStart"
                style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

                <el-button type="primary" plain icon="el-icon-download" style="margin: 0 10px 10px 0;" size="small"
                    @click="exportPdfs_xf">
                    导出考察报告手册
                </el-button>
               
            </div> -->
        </el-row>
        <div class="dividerBar"></div>

        <el-table v-tableFit ref="multipleTable" :data="DataList" height="100%" size="small" stripe border
            style="width:fit-content;width:100%;" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column type="index" width="65" label="序号" align="center" />

            <el-table-column prop="username" label="考察人员" width="135" align="center" />
            <el-table-column prop="visit_date" label="考察时间" width="240" align="center">
            </el-table-column>

            <el-table-column prop="enter_name" label="单位名称" show-overflow-tooltip width="200" align="center" />
            <el-table-column prop="firm_principal" label="联 系 人" width="120" align="center" />

            <el-table-column prop="tel" label="联系方式" width="135" align="center" />

            <el-table-column prop="address" label="单位详细地址"></el-table-column>
            <el-table-column fixed="right" label="操作" width="220" align="center">
                <template slot-scope="scope">
                    <el-tag class="pointer" type="success" size="mini" @click="exportReport(scope.row)">导出考察表</el-tag>

                    <el-tag class="pointer ml10" type="primary" size="mini" @click="modify(scope.row)"
                        v-if="$store.state.teacher.id == scope.row.create_uid">
                        编辑
                    </el-tag>
                    <!-- <el-tag class="pointer" v-else type="text" size="mini" @click="viewInfo(scope.row)">详情</el-tag> -->
                    <el-popconfirm
                        v-if="isAdmin || ( $store.state.teacher.id == scope.row.create_uid)"
                        width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info" iconColor="red"
                        @confirm="handleDelete(scope.row)" placement="left" title="是否确定删除？">
                        <el-tag class="pointer" slot="reference" type="danger" size="mini"
                            style="margin-left: 10px;">删除</el-tag>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination v-if="showPage" @current-change="handlePageChange" @size-change="handleSizeChange"
            :current-page="page.current_page" :page-size="page.per_page" :total="page.count"
            layout="total, sizes,prev, pager, next" :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
        </el-pagination>
        <el-pagination v-if="!showPage" :current-page="page.current_page" :total="page.count" layout="total"
            style="margin-top: 10px;">
        </el-pagination>

        <formdialog v-if="dialogshow" :id="EditItem.id" :xunfangsp="xunfang_sp"></formdialog>

        <el-dialog ref="ruleForm" title="新增/编辑考察报告" :visible.sync="showFormDialog" :close-on-click-modal="false"
            :modal-append-to-body="false" :before-close="resetForm" :destroy-on-close="true" custom-class="cus_dialog"
            width="800px">

            <el-form :model="EditItem" label-width="120px" ref="formData">

                <el-form-item class="is-required" label="考察时间" prop="visit_date">
                    <el-date-picker v-model="EditItem.visit_date" type="date" value-format="yyyy-MM-dd"
                        placeholder="选择日期" :clearable="false">
                    </el-date-picker>
                </el-form-item>

                <el-form-item class="is-required" label="考察人员">
                    <el-select v-model="EditItem.teacher_id" filterable remote clearable reserve-keyword
                        placeholder="请选择考察人员" :remote-method="searchTeacher" style="width:80%">
                        <el-option v-for="item in teacherList" :key="item.id"
                            :label="item.username + (item.dept_name ? '[' + item.dept_name + ']' : '')"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item class="is-required" label="单位名称" style="margin-bottom:0;">
                    <el-tag v-for="item in multipleSelectionStu" :key="item.id" type="success"
                        style="margin-right: 5px;">{{
                            item.firm_name }}</el-tag>
                    <el-button type="primary" icon="el-icon-plus" size="small" plain @click="setStuSelection">
                        选择企业</el-button>
                </el-form-item>
                <template v-if="multipleSelectionStu && multipleSelectionStu.length > 0">

                    <el-form-item class="" label="单位性质" style="margin-bottom:0;">
                        {{ qyxz[multipleSelectionStu[0].info] ?
                            qyxz[multipleSelectionStu[0].info].param_desc :
                            (multipleSelectionStu[0].tyc && multipleSelectionStu[0].tyc.enter_type ?
                                multipleSelectionStu[0].tyc.enter_type : '-') }}
                    </el-form-item>

                    <el-row>
                        <el-col :span="12">
                            <el-form-item class="" label="单位法人" style="margin-bottom:0;">
                                {{ multipleSelectionStu[0].legal_person ? multipleSelectionStu[0].legal_person :
                                    (multipleSelectionStu[0].tyc && multipleSelectionStu[0].tyc.tyc_json ?
                                        multipleSelectionStu[0].tyc.tyc_json.legalPersonName :
                                        "-") }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item class="" label="联系方式" style="margin-bottom:0;">
                                {{ multipleSelectionStu[0].co_tel || '-' }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item class="" label="联 系 人" prop="enter_name" style="margin-bottom:0;">
                                {{ multipleSelectionStu[0].firm_principal || '-' }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item class="" label="联系方式" style="margin-bottom:0;">
                                {{ multipleSelectionStu[0].tel || '-' }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item class="" label="组织机构代码" style="margin-bottom:0;">
                                {{ multipleSelectionStu[0].firm_num || '-' }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item class="" label="单位详细地址" style="margin-bottom:0;">
                                {{ multipleSelectionStu[0].address || '-' }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item class="" label="考察报告" prop="visit_summary">
                                <el-input disabled type="textarea" v-model="multipleSelectionStu[0].research_report"
                                    :autosize="{ minRows: 10 }" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>


                <el-form-item class="" label="附件图片">
                    <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                        v-for="(img, idx) in EditItem.fieldname" :key="idx">
                        <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                            :preview-src-list="[img.fileUrl || img.src]" img="cover"></el-image>
                    </div>
                </el-form-item>
            </el-form>
            <div class="" slot="footer">
                <el-button @click="onSubmit(0)" type="primary">提交</el-button>
            </div>
        </el-dialog>
        <el-dialog width="850px" title="选择企业" :visible.sync="showStuList" :close-on-click-modal="false"
            :modal-append-to-body="false" @close="showStuList = false" :destroy-on-close="true"
            custom-class="cus_dialog">
            <div class="flexCloumn" style="width:100%;height:100%;">
                <div class="flexStart">
                    <el-input placeholder="请输入关键字" v-model="searchFormStu.firm_name" size="small"
                        class="input-with-select" style="width:300px;margin: 0 10px 10px 0;" clearable>
                        <el-select v-model="searchFormStu.ktype" slot="prepend" placeholder="请选择" style="width:120px">
                            <el-option label="企业名称" value="username"></el-option>
                        </el-select>
                    </el-input>
                    <el-button icon="el-icon-search" style="margin: 0 10px 10px 0;" size="small" type="primary"
                        @click="() => { pageStu.current_page = 1; getEnterList() }">搜索
                    </el-button>
                </div>
                <el-table class="flex_1" height="100%" ref="multipleStuTable" :data="stuList"
                    @selection-change="handleSelectionStuChange" size="small" stripe border
                    style="width:fit-content;width:100%;">
                    <el-table-column type="selection" width="50">
                    </el-table-column>
                    <el-table-column prop="firm_name" label="企业名称" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="firm_principal" label="企业联系人" width="120">
                    </el-table-column>
                    <el-table-column prop="tel" label="联系电话" width="135">
                    </el-table-column>
                    <el-table-column prop="address" label="企业地址" width="300"></el-table-column>
                </el-table>
                <el-pagination @current-change="handlePageChangeStu" @size-change="handleSizeChangeStu"
                    :current-page="pageStu.current_page" :page-size="pageStu.per_page" :total="pageStu.count"
                    layout="total, sizes,prev, pager, next" :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
                </el-pagination>
            </div>
            <div class="" slot="footer">
                <el-button @click="confirmStuSelect" type="primary"
                    :disabled="multipleSelectionStu.length === 0">确定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import formdialog from "./com/report_formdialog.vue"
import tchtree from '../../com/tchTree.vue'
import { mapState } from 'vuex'
import moment from "moment";
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
import util from "@/utils/util";
import citys from "../../../utils/arae_value.js";
import { exportWord } from "../../../utils/exportword";
import { ExportBriefDataDocx, ExportBriefDataDocx1 } from "@/utils/exportBriefDataDocxNew";

export default {
    components: {
        formdialog,
        tchtree
    },
    data() {
        return {
            temphead: "//school.mmteck.cn/static/temp.png",

            dialogshow: false,
            showFormDialog: false,
            showStuList: false,
            searchForm: {
                audit_status: "",
                ktype: "username",
                keyword: "",
                class_id: "",
                month: ""

            },
            EditItem: {
                id: 0,
                visit_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                fieldname: [],
                stu_list: [],
            },
            DataList: [],
            listForm: [],
            listState: [],
            ClassList: [],
            ClassTree: [],
            defaultProps: {
                label: 'name',
                value: 'id',
                children: 'children',
                emitPath: false,
                checkStrictly: true
            },
            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0,

            },
            pageStu: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0,

            },
            multipleSelection: [],

            xunfang_sp: 0,//是否开启考察报告审核
            showPage: true,
            pmid: 0,
            rules: {
                visit_date: [
                    { required: true, message: "请选择考察报告时间", trigger: 'change' }
                ],
                visit_form: [
                    { required: true, message: '请选择考察报告形式', trigger: 'change' }
                ],
                situation: [
                    { required: true, message: '请选择考察报告情况', trigger: 'change' }
                ],
                visit_summary: [
                    { required: true, message: '请输入考察报告小结', trigger: 'blur' }
                ],
                enter_name: [
                    { required: true, message: '请输入企业', trigger: 'blur' }
                ],
            },
            stuList: [],
            multipleSelectionStu: [],
            // 选点
            mapShow: false,

            zoom: 16,
            center: [116.397477, 39.908692],
            events: {
                click(e) {
                    let { lng, lat } = e.lnglat;
                    self.clickLng = lng;
                    self.clickLat = lat;

                    // 这里通过高德 SDK 完成。
                    var geocoder = new AMap.Geocoder({
                        radius: 1000,
                        extensions: 'all'
                    });
                    geocoder.getAddress([self.clickLng, self.clickLat], function (status, result) {
                        if (status === 'complete' && result.info === 'OK') {
                            if (result && result.regeocode) {
                                self.$nextTick(() => {
                                    self.center = [self.clickLng, self.clickLat];
                                    self.markers[0].position = [self.clickLng, self.clickLat];
                                    self.texts[0].position = [self.clickLng, self.clickLat];
                                    self.texts[0].text = result.regeocode.formattedAddress;
                                    self.address = result.regeocode.formattedAddress;
                                });
                            }
                        }
                    });
                },
                lng: 0,
                lat: 0
                // },
            },
            markers: [
                {
                    position: [116.397477, 39.908692],
                    events: {
                        // click: () => {
                        //   alert('click marker');
                        // },
                        // dragend: e => {
                        //   console.log('---event---: dragend');
                        //   this.markers[0].position = [e.lnglat.lng, e.lnglat.lat];
                        // }
                    },
                    visible: true,
                    draggable: false,
                    template: '<span></span>'
                }
            ],
            texts: [
                {
                    position: [116.397477, 39.908692],
                    text: '天安门',
                    offset: [0, -70]
                    // events: {
                    //   click: () => {
                    //     alert('click text');
                    //   }
                    // }
                }
            ],
            posBoxWidth: 0,//监听盒子宽度
            posBoxId: '',
            locationSource: [],
            latLng: "",
            timer: null,
            teacherList: [],
            searchFormStu: {},
            qyxz: [],
            qyxzObj:{},
        };
    },
    watch: {
        showFormDialog(n, o) {
            console.log(n, o)
            if (!n) {
                this.mapShow = false
            }
        }
    },
    filters: {
        handleDate(value) {	// value是输入框的内容，也是要显示的内容
            if (value) {
                return value.substring(0, 10)
            }
        }
    },


    mounted() {
        this.getComType()
        this.getConfig()
        this.getOrgTree1()
    },
    computed: {
        ...mapState(['teacher']),
        isAdmin() {
            if (this.teacher.sys_roles) {
                let roles = this.teacher.sys_roles
                let arr = roles.filter(r => r.role_code.indexOf('admin') >= 0).map(r => r.role_code)
                return arr.length > 0
            }
        },
    },
    methods: {
        //是否开启考察报告审核
        getConfig() {
            this.$http.post("/api/sys_sch_config_list", { ktype: "ktype", keyword: 'xunfang_sp' }).then(res => {
                if (res.data.data && res.data.data[0]) {
                    this.xunfang_sp = Number(res.data.data[0].kvalue)
                }
            })
        },
        getOrgTree1() {
            this.$http.post("/api/get_teacher_info").then(res => {
                /*for (let item of res.data.class_names) {
                    if (item.type == "班主任" && window.pmid != 216) {
                        this.searchForm.office_id = item.class_id
                        break
                    }
                }*/

                this.formData = res.data;



                this.getAllPickerVal()
                //this.getOrgTree()


            })

        },
        getOrgTree() {
            this.$http.post("/api/teacher_class_tree").then(res => {
                this.ClassTree = res.data.tree
            })


        },
        getAllPickerVal() {
            //考察报告形式&&考察报告情况
            this.$http.post("/api/sys_param_list", { param_type: "'VISIT_FORM','VISIT_SITUATION'" }).then((res) => {
                let listForm = {}, listState = {};
                res.data.map((item, index, arr) => {
                    if (item.param_type == 'VISIT_FORM') {
                        listForm[item.param_value] = item.param_desc

                    }
                    if (item.param_type == 'VISIT_SITUATION') {

                        listState[item.param_value] = item.param_desc

                    }
                })

                this.listForm = listForm
                this.listState = listState

                // 查询classid
                this.$http.post('/api/get_tch_cls_data').then(res => {
                    let classids = [];
                    if (res.data.bj_ids) {
                        if (res.data.bj_ids.indexOf(",") > 0) {
                            classids = classids.concat(res.data.bj_ids.split(','))
                        } else {
                            classids.push(res.data.bj_ids)
                        }

                    }

                    if (res.data.sxxz_ids) {
                        if (res.data.sxxz_ids.indexOf(",") > 0) {
                            classids = classids.concat(res.data.sxxz_ids.split(','))
                        } else {
                            classids.push(res.data.sxxz_ids)
                        }
                    }
                })
                this.getList()
            })
        },
        getList() {
            return new Promise((resolve, reject) => {
                let data = {
                    page: this.page.current_page,
                    pagesize: this.page.per_page,
                    month: this.searchForm.month,

                    // class_id: this.searchForm.class_id,
                    office_id: this.searchForm.office_id,
                    audit_status: this.searchForm.audit_status == -1 ? '' : this.searchForm.audit_status,
                    is_draft: this.searchForm.audit_status == -1 ? 1 : '',
                    showPage: this.showPage

                }
                if (this.searchForm.ktype == "enter_name") {
                    data.enter_name = this.searchForm.keyword
                } else if (this.searchForm.ktype == "username") {
                    data.username = this.searchForm.keyword
                }
                this.$http.post("/api/teacher_research_report_list", data).then(res => {
                    for (let item of res.data.data) {
                        item.stu_list = JSON.parse(item.stu_list)
                    }
                    this.DataList = res.data.data
                    this.page = res.data.page ? res.data.page : this.page
                    resolve()
                })
            })
        },
        onSearch() {
            let _this = this
            this.page.current_page = 1;
            if (this.searchForm.ktype == "username" && this.searchForm.keyword) {
                this.showPage = false
            } else {
                _this.showPage = true
            }

            this.getList().then(_ => {
                if (_this.searchForm.ktype == "username" && _this.searchForm.keyword) {

                    if (_this.DataList && _this.DataList.length > 0) {
                        _this.page.count = _this.DataList.length
                        _this.multipleSelection = _this.DataList
                        _this.DataList.forEach(row => {
                            this.$refs.multipleTable.toggleRowSelection(row);
                        });
                    } else {
                        _this.page.count = 0
                        _this.multipleSelection = []
                        this.$refs.multipleTable.clearSelection();
                    }
                }
            })
        },
        handlePageChange(page) {
            this.page.current_page = page
            this.getList()
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getList()
        },
        handlePageChangeStu(page) {
            this.pageStu.current_page = page
            this.getEnterList()
        },
        handleSizeChangeStu(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getEnterList()
        },
        handleSelectionChange(val) {
            console.log(val)
            this.multipleSelection = val;
        },
        addNew() {
            this.searchTeacher().then(_ => {
                this.resetForm()
            })
        },
        modify(row) {

            this.searchTeacher().then(_ => {
                this.$http.post("/api/teacher_research_report_info", {
                    id: row.id
                }).then((res) => {



                    // let formData = JSON.parse(JSON.stringify(row))
                    let formData = res.data
                    this.multipleSelectionStu = row.stu_list
                    let fieldname = []
                    if (res.data.license_imge) {
                        fieldname = [{ fileName: 'license_imge', src: res.data.license_imge }]
                    }

                    if (formData.research_fieldname && this.isJSON(formData.research_fieldname)) {
                        let aa = JSON.parse(formData.research_fieldname)
                        fieldname = fieldname.concat(aa)
                    }
                    console.log(603, res.data)

                    formData.fieldname = fieldname

                    this.showFormDialog = true

                    if (formData.teachers) {
                        formTeacher.bzr = ''
                        for (let item of formData.teachers) {
                            formTeacher.bzr = Number(item.teacher_id)
                        }
                        //this.teacherList = formData.teachers
                    }
                    this.EditItem = { ...formData }
                });
            })
        },
        viewInfo(row) {
            this.EditItem = row
            this.dialogshow = true;
        },

        searchTeacher(e) {
            return new Promise((resolve, reject) => {
                let params = {
                    page: 1,
                    pagesize: 200,
                    ktype: "username",
                    keyword: e
                };
                // sys_dept_user_related_list
                this.$http.post("/api/t_sch_teachers_list", params).then((res) => {
                    for (let item of res.data.data) {
                        if (item.dept_roles && item.dept_roles.length > 0) {
                            item.dept_name = item.dept_roles.map(e => { return e.name }).join(",")
                        }
                        item.teacher_id = item.id
                    }
                    this.teacherList = res.data.data;

                    resolve()
                });
            })
        },
        getComType() {
            return new Promise(resolve => {
                this.$http
                    .post("/api/sys_param_list", {
                        param_type: "'ENTER_INFO'",
                        used: 0
                    })
                    .then(res => {
                        let qyxz = [], qyxzObj = {};
                        for (let item of res.data) {
                            if (item.param_type == "ENTER_INFO") {
                                qyxz.push(item);
                                qyxzObj[item.param_value] = item.param_desc

                            }
                        }
                        this.qyxz = qyxz;
                        this.qyxzObj = qyxzObj;
                        resolve();
                    });
            });
        },

        getEnterList() {
            return new Promise((resolve, reject) => {
                let data = {};
                data.login_name = this.searchFormStu.login_name;
                data.firm_name = this.searchFormStu.firm_name;
                data.firm_num = this.searchFormStu.firm_num;
                data.tel = this.searchFormStu.tel;
                data.info = this.searchFormStu.info;
                data.email_status = this.searchFormStu.email_status;
                data.is_cooperation = this.searchFormStu.is_cooperation;
                data.check_status = this.searchFormStu.check_status;
                data.qyly = 1;
                data.is_register = 1
                data.page = this.pageStu.current_page;
                data.pagesize = this.pageStu.per_page;
                data.is_report = 1;
                this.$http.post("/api/enterprise_list", data).then(res => {
                    res.data.data && res.data.data.map(a => {
                        if (a.area_id) {
                            let areaNames = util.getParentAreaByValue(citys, a.area_id)
                            if (areaNames && areaNames.length > 0) {
                                a.area_name_new = areaNames.map(a => a.label).reverse().join('/');
                            }
                        }
                    })
                    this.stuList = res.data.data;
                    this.pageStu = res.data.page;
                    resolve()
                });
            })
        },
        exportXls() {
            this.$http.post("/api/teacher_visit_list_export", {
                page: 1,
                pagesize: 2000,
                month: this.searchForm.month,
                enter_name: this.searchForm.keyword,
                // class_id: this.searchForm.class_id,
                office_id: this.searchForm.office_id,
                audit_status: this.searchForm.audit_status == -1 ? '' : this.searchForm.audit_status,
                is_draft: this.searchForm.audit_status == -1 ? 1 : ''
            }).then(res => {
                if (res.data.url) {
                    window.open(res.data.url)
                }
            })
        },
        exportPdfs_xf(row) {
            console.log(this.multipleSelection)
            let arr = []

            if (!this.multipleSelection || this.multipleSelection.length == 0) {
                this.$message.warning('请先选择教师~')
            } else {
                this.multipleSelection.map(a => {
                    arr.push({
                        "id": a.teacher_id,
                        "name": a.username,
                        "dataid": a.id,
                        visit_date: a.visit_date.substring(0, 10),
                    })
                })
                let params = {
                    "stuArr": arr,
                    "pmid": window.pmid,
                    "latest": 1,
                    pageFrom: 'xflist',
                    month: this.searchForm.month,
                }
                // this.$http.post("http://localhost:30022/exportTchPdf_xf", params).then(res => {

                this.$http.post(this.$pdfUrl + "/exportTchPdf_xf", params).then(res => {
                    if (arr.length > 10) {
                        let loadingInstance = this.$loading({
                            lock: true,
                            text: "正在导出请稍等...",
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.5)'
                        })
                        this.timer = setInterval(async () => {
                            let result = await this.$http.post(this.$pdfUrl + "/getFileUrl", { rkey: res.data.id, noloading: true })
                            if (result.data.url) {
                                clearInterval(this.timer)
                                loadingInstance.close()
                                window.open(result.data.url, "_blank")
                            }
                        }, 3000)
                    } else {
                        this.$message({
                            type: 'success',
                            message: '生成成功!'
                        });
                        window.open(res.data.url, "_blank")
                    }
                })

            }
        },

        handleDelete(row) {
            this.$http.post("/api/teacher_research_report_delete", {
                id: String(row.id)
            }).then(res => {
                this.$message.success('删除成功')
                this.getList();
            })
        },
        onSubmit() {
            if (!this.EditItem.teacher_id) { this.$message.error("请选择考察时间~"); return }
            if (!this.EditItem.visit_date) { this.$message.error("请选择考察人员~"); return }
            if (!this.multipleSelectionStu || this.multipleSelectionStu.length <= 0) { this.$message.error("请选择企业"); return }

            // if (!this.EditItem.fieldname || this.EditItem.fieldname.length == 0) {
            //     this.$message.error("请上传图片~")
            //     return
            // }
            let params = { ...this.EditItem }
            params.stu_list = JSON.stringify(this.multipleSelectionStu)
            params.fieldname = JSON.stringify(this.EditItem.fieldname)
            params.stu_num = this.multipleSelectionStu.length


            this.$http.post('/api/teacher_research_report_edit', params).then(res => {
                this.$message.success('提交成功')
                this.getList()
                this.resetForm()
            })

        },
        handleSelectionStuChange(selection) {
            if (selection.length > 1) {
                //移除上一次选中行数据
                selection.shift();
                //修改选中图标为未选中状态
                this.$refs.multipleStuTable.clearSelection();
                //将当前选中行改为选中状态
                this.$refs.multipleStuTable.toggleRowSelection(selection[0]);
            }
            this.multipleSelectionStu = selection;
            console.log(804, selection)
        },
        setStuSelection() {
            this.getEnterList().then(_ => {

                this.showStuList = true
                this.$nextTick(() => {
                    this.multipleSelectionStu.forEach(row => {
                        this.$refs.multipleStuTable.toggleRowSelection(
                            // 此处必须在 tableData 中查找对应的数据，否则 toggleRowSelection 会失效
                            this.stuList.find(r => {
                                return r.id === row.id;
                            }),
                            true
                        )
                    })
                })
            })
        },
        confirmStuSelect() {
            this.showStuList = false
            this.EditItem.enter_name = this.multipleSelectionStu[0].firm_name
            this.EditItem.enter_id = this.multipleSelectionStu[0].id
            this.EditItem.job = this.multipleSelectionStu[0].job
            this.EditItem.fieldname = []
            if (this.multipleSelectionStu[0].license_imge) {
                this.EditItem.fieldname = [{ fileName: 'license_imge', src: this.multipleSelectionStu[0].license_imge }]
            }
            let fieldname = []
            if (this.multipleSelectionStu[0].research_fieldname && this.isJSON(this.multipleSelectionStu[0].research_fieldname)) {
                fieldname = JSON.parse(this.multipleSelectionStu[0].research_fieldname)
                this.EditItem.fieldname = this.EditItem.fieldname.concat(fieldname)
            }
            console.log(825, this.EditItem.fieldname)
        },
        resetForm() {
            this.showFormDialog = !this.showFormDialog
            this.multipleSelectionStu = []
            this.EditItem = {
                id: 0,
                visit_date: moment().format('YYYY-MM-DD HH:mm:ss'),
                fieldname: [],
                stu_list: [],
            }
            this.$nextTick(() => {
                this.$refs.formData.resetFields();
            })
        },
        onChangeLocation(value, callback) {
            console.log(763, value)
            let locationSource = [];
            if (value) {
                this.EditItem.visit_address = value;
                $.get('https://restapi.amap.com/v3/assistant/inputtips?output=json&keywords=' + this.EditItem.visit_address + '&key=0b5d4c06598b47042b9b5257889f7e26', (res) => {
                    if (res.tips) {
                        locationSource = res.tips.map(o => { return { value: o.name } })
                    }
                    this.locationSource = res.tips;
                    callback(locationSource);
                })
            } else {
                callback(locationSource);
            }

        },
        onSelect(obj) {
            console.log(781, obj)
            this.EditItem.visit_address = obj.value;
            this.locationSource.forEach(element => {
                console.log(776, element)
                if (element.name == this.EditItem.visit_address) {
                    if (element.location && element.location.length > 0) {
                        this.mapShow = true;
                        const locArr = element.location.split(',').map(Number);
                        this.center = locArr;
                        this.markers[0].position = locArr;
                        this.texts[0].position = locArr;
                    }
                    this.texts[0].text = '<b>' + element.name + '</b></br><span>' + element.address + '</span>';
                    this.EditItem.visit_address = element.name + '(' + element.address + ')';
                    this.latLng = element.location;
                    return;
                }
            });
        },
        exportReport(row) {
            this.$http.post("/api/teacher_research_report_info", {
                id: row.id
            }).then(async  (res) => {
                let formData = res.data
                let fieldname = []
                if (res.data.license_imge) {
                    fieldname = [{ fileName: 'license_imge', src: res.data.license_imge }]
                }
                if (formData.research_fieldname && this.isJSON(formData.research_fieldname)) {
                    let aa = JSON.parse(formData.research_fieldname)
                    fieldname = fieldname.concat(aa)
                }


                if (fieldname && fieldname.length>0) {
                for (let item of fieldname) {
                    if (item.src) {
                        item.src_base64 = await util.getBase64Sync(item.src)
                    }
                }
                }
                formData.fieldname = fieldname

                formData.report_date = this.$moment(formData.visit_date).format("YYYY年MM月DD日")
                formData.report_date = ""
                formData.username = formData.username || ''
                formData.dept_name = ''
                formData.dept_admin = ''
                if (this.$store.state.teacher.dept_roles && this.$store.state.teacher.dept_roles.length>0) {
                    for (let item of this.$store.state.teacher.dept_roles){
                        if (item.role_name == "系主任") {
                            formData.dept_name = item.name
                            formData.dept_admin = item.username
                        }
                    }
                   
                }
               if (formData.teachers) {
                    formTeacher.bzr = ''
                    for (let item of formData.teachers) {
                        formTeacher.bzr = Number(item.teacher_id)
                    }
                }

                setTimeout(() => {
                    console.log(916, formData)
                    let imgSize = {
                        src_base64: [300, 450],
                        centered: false,
                    }
                    // exportWord('./exportTemp/实习企业考察报告.docx', formData, `实习企业考察报告【${formData.enter_name}】.docx`)
                    ExportBriefDataDocx('./exportTemp/实习企业考察报告.docx', formData, `实习企业考察报告【${formData.enter_name}】.docx`, imgSize) 

                }, 200);

            });
        },

        async exportReportAll() {
            console.log(1000, 11111)

            let data = {};
            data.qyly = 1;
            data.is_register = 1
            data.page = 1;
            data.pagesize = 10000000000;
            data.is_report = 1;
            let dataList = []
            this.$http.post("/api/enterprise_list", data).then(async (res) => {
                for (let a of res.data.data) {
                    if (a.area_id) {
                        let areaNames = util.getParentAreaByValue(citys, a.area_id)
                        if (areaNames && areaNames.length > 0) {
                            a.area_name_new = areaNames.map(a => a.label).reverse().join('/');
                        }
                    }
                    let fieldname = []
                    if (a.license_imge) {
                        fieldname = [{ fileName: 'license_imge', src: a.license_imge }]
                    }
                    if (a.research_fieldname && this.isJSON(a.research_fieldname)) {
                        let aa = JSON.parse(a.research_fieldname)
                        fieldname = fieldname.concat(aa)
                    }

                    if (fieldname && fieldname.length > 0) {
                         for (let item of fieldname) {
                            if (item.src) {
                                item.src_base64 = await util.getBase64Sync(item.src)
                            }
                        }
                    }
                    a.fieldname = fieldname

                    a.report_date = ""
                    a.username = ''
                    a.dept_name = ''
                    a.dept_admin = ''
                    a.enter_name = a.firm_name
                    console.log(this.qyxzObj,a.info)
                    a.param_desc = this.qyxzObj[a.info] || ''
                    if (a.research_report) {
                        dataList.push(a)
                    }
                }
                let imgSize = {
                    src_base64: [300, 450],
                    centered: false,
                }

                if (dataList && dataList.length > 0) {
                    console.log(1004, dataList)
                    ExportBriefDataDocx1('./exportTemp/实习企业考察报告.docx', dataList, `实习企业考察报告.zip`, imgSize)
                }
                // setTimeout(() => {
                   
                // }, 2000);

            });
        },
    },
}
</script>

<style scoped="scoped">
/**找到表头那一行，然后把里面的复选框隐藏掉**/
::v-deep .el-table__header-wrapper .el-table__header .el-checkbox {
    display: none;
}

::v-deep .is-disabled .el-textarea__inner {
    color: #606266;
}

.spuser {
    display: inline-block;
    border: 1px solid rgb(255, 148, 62);


    padding: 2px;
    margin-right: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    padding: 4px 10px;
}

.spuser img {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
}

.imgaddbtn {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dotted #ccc;
    font-size: 30px;
    cursor: pointer;
}

.imgremove {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;

    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
    color: #ccc;
}

.imgremove:hover {
    color: orange;
}
</style>
